<template>
  <div id="cartpay">
    <tmpl_header :hide="['search', 'nav', 'shopcart']" subtitle="提交订单" />

    <cart_steps :step="3"></cart_steps>

    <div class="settle" v-if="order">
      <section v-if="payResult === 'success'" class="card success">
        <div class="info">
          <h3>已成功付款！</h3>
          <p>所购商品已在处理中，我们将尽快为您发货！</p>
          <router-link to="/home">继续购物</router-link>
        </div>
        <div class="order">
          <p>
            <label>订单号：</label>
            <span>{{ order.orderNo }}</span>
          </p>
          <p class="p">
            <label>在线支付：</label>
            <span>{{ formatPrice(order.oldPrice) }}</span>
          </p>
          <router-link
            :to="'/center/order?order=' + order.orderId"
            class="bright"
            >订单详情</router-link
          >
        </div>
      </section>

      <section v-if="payResult === 'fail'" class="card fail">
        <div class="info">
          <h3>支付失败</h3>
          <p>
            如果您已付款，可能因交易量激增导致订单延迟处理（最长数秒至数分钟）<br />您可稍后
            <a href="javascript:location.reload(true);">刷新</a> 本页面，或前往
            <router-link to="/center/orders">我的订单</router-link> 查看支付情况
          </p>
          <router-link to="/home">继续购物</router-link>
        </div>
        <div class="order">
          <p>
            <label>订单号：</label>
            <span>{{ order.orderNo }}</span>
          </p>
          <p class="p">
            <label>在线支付：</label>
            <span>{{ formatPrice(order.oldPrice) }}</span>
          </p>
          <router-link
            :to="'/center/order?order=' + order.orderId"
            class="bright"
            >订单详情</router-link
          >
        </div>
      </section>

      <section v-if="payResult === 'fail'" class="card help">
        <h3>付款遇到问题了？先看看是不是由于以下原因：</h3>
        <ul>
          <li>
            <h4>- 所需支付金额超过了银行支付限额</h4>
            <p>建议您登录网上银行提高上限额度，即能轻松支付。</p>
          </li>
          <li>
            <h4>- 支付宝页面显示错误或者空白</h4>
            <p>
              支付宝对不同浏览器的兼容性有限，导致无法正常支付，建议您使用IE浏览器进行支付操作。
            </p>
          </li>
          <li>
            <h4>- 已扣款，云方交易单仍显示“未付款”</h4>
            <p>
              可能由于支付宝数据没有即时传输，请不要担心，稍后刷新页面查看。如较长时间仍显示未付款，可联系在线客服为您解决。
            </p>
          </li>
        </ul>
      </section>

      <section v-if="!payResult" class="notice">
        <h3>订单提交成功，请您尽快付款！订单号：{{ order.orderNo }}</h3>
        <p>
          请您在
          <b
            >{{ remainingTime[0] }}小时{{
              10 > remainingTime[1] ? `0${remainingTime[1]}` : remainingTime[1]
            }}分{{
              10 > remainingTime[2] ? `0${remainingTime[2]}` : remainingTime[2]
            }}秒</b
          >
          内完成支付，否则订单会被自动取消
        </p>
      </section>

      <section v-if="!payResult" class="price">
        预付金额
        <b>{{ formatPrice(order.oldPrice) }}</b>
        <router-link :to="'/center/order?order=' + order.orderId"
          >订单详情</router-link
        >
      </section>

      <section v-if="payResult !== 'fail'" class="card">
        <div class="addr">
          <h3>收货信息</h3>
          <div>
            <p>
              <b>{{ order.shippingPerson }}</b
              >{{ order.shippingMobile }}
            </p>
            <p>
              {{ order.shippingProvince }} {{ order.shippingCity }}
              {{ order.shippingCounty }} {{ order.shippingAddress }}
            </p>
          </div>
        </div>
        <div class="pros">
          <h3>商品信息</h3>
          <ul>
            <li v-for="item in order.goods" :key="item.goodsId">
              <p>{{ item.goodsName }}</p>
              <span>x{{ item.goodsNum }}</span>
            </li>
          </ul>
        </div>
      </section>

      <section v-if="!payResult" class="card paytype">
        <div class="list">
          <ul>
            <li class="weixin" @click="payBy('weixin')">微信支付</li>
            <!--li class="unionpay" @click="payBy('weixin')">在线支付</li-->
            <li class="alipay" @click="payBy('alipay')">支付宝</li>
          </ul>
        </div>
      </section>

      <form
        ref="aliForm"
        style="display: none"
        method="GET"
        :action="'//yfapi.szyunfang.com' + alipayUrl"
        target="_blank"
      >
        <input type="hidden" name="orderNo" :value="order.orderNo" />
        <input type="hidden" name="returnUrl" :value="returnUrl" />
      </form>
    </div>

    <tmpl_footer style="margin-top: 0" />

    <div class="modal wxpay" :class="{ show: pay === 'weixin' }">
      <div class="container">
        <div class="mhead">
          <h3>微信支付</h3>
          <a @click="closePay('weixin')" class="close"></a>
        </div>
        <div class="mbody">
          <img
            v-if="weixinQRCode === 'loading'"
            class="loading"
            src="../../assets/img/qrloading.svg"
          />
          <img v-if="weixinQRCode !== 'loading'" :src="weixinQRCode" />
          <p :class="{ loading: weixinQRCode === 'loading' }">
            {{
              weixinQRCode === "loading"
                ? "支付码加载中，请等待"
                : "请使用微信扫码支付"
            }}
          </p>
        </div>
      </div>
    </div>
    <!--微信支付弹窗-->

    <div class="modal alipay" :class="{ show: pay === 'alipay' }">
      <div class="container">
        <div class="mhead">
          <a @click="closePay('alipay')" class="close"></a>
        </div>
        <div class="mbody">
          <div class="icon warning"></div>
          <div class="msg">请在新打开的页面完成付款</div>
          <div class="info">
            付款前请不要关闭此窗口<br />完成付款后请根据情况点击下面的按钮
          </div>
        </div>
        <div class="mfoot">
          <a class="move" @click="checkALiPay">已完成付款</a>
          <a class="submit" @click="closePay('alipay')">付款遇到问题</a>
          <a class="back" @click="closePay('alipay')">返回选择其他付款方式</a>
        </div>
      </div>
    </div>
    <!--支付宝支付弹窗-->
  </div>
</template>

<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import cart_steps from "./_steps.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";
import QRCode from "qrcode";

export default {
  data() {
    return {
      loading: true,
      alipayUrl: apis.pay.alipay,
      returnUrl: "",
      orderId: "",
      order: null,
      paytime: 0,
      remainingTime: [0, 0, 0],

      pay: "",
      weixinQRCode: "",
      payResult: "",
      checkPayTimer: false,
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
    cart_steps,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  created: function () {
    this.orderId = this.$route.query.order;

    if (!this.username)
      this.$router.push({
        path: "/signin",
        query: { to: encodeURI("/pay?order=" + this.orderId) },
      });

    this.returnUrl = location.href + "&check=true";

    api.all([this.loadOrder()]).then(
      api.spread(() => {
        this.loading = false;
      })
    );
  },

  methods: {
    loadOrder: function () {
      return api
        .post(apis.order.detail, {
          orderId: this.orderId,
        })
        .then((res) => {
          this.order = res;

          const create = new Date(this.order.addTime.replace(/-/g, "/"));
          // console.log(create);
          const endline = create.getTime() + 24 * 3600 * 1000;
          this.paytime = endline;

          if (this.order.orderStatus == "1") {
            this.payResult = "success";
          } else if (
            this.order.orderStatus == "0" ||
            this.order.orderStatus == "23"
          ) {
            this.payTimer();
          } else {
            this.$router.push({
              path: "/center/order",
              query: { order: this.orderId },
            });
          }
        });
    },

    formatPrice(price) {
      let fen = (price * 100) % 100;
      return Math.floor(price) + "." + (fen < 10 ? "0" : "") + fen;
    },

    payTimer() {
      const now = new Date().getTime();
      if (this.paytime && now < this.paytime) {
        const dt = this.paytime - now;
        const hour = Math.floor(Math.floor(dt / 1000) / 3600);
        const min = Math.floor(Math.floor(dt / 1000) / 60) % 60;
        const sec = Math.floor(dt / 1000) % 60;
        this.remainingTime = [hour, min, sec];
        setTimeout(this.payTimer, 200);
      } else {
        this.remainingTime = [0, 0, 0];
      }
    },

    payBy(type) {
      const page = this;
      switch (type) {
        case "weixin":
          page.pay = "weixin";
          page.weixinQRCode = "loading";
          var QRBuild = (url) => {
            return QRCode.toDataURL(url)
              .then((tpian) => {
                page.weixinQRCode = tpian;
                page.checkWXPay();
              })
              .catch((err) => {
                console.error(err);
              });
          };

          if (page.order.qrCodeUrl) {
            QRBuild(page.order.qrCodeUrl);
          } else {
            return api
              .post(apis.pay.weixin, {
                orderNo: page.order.orderNo,
              })
              .then((res) => {
                // page.weixinQRCode = res;
                QRBuild(res);
              });
          }
          break;

        case "alipay":
          page.pay = "alipay";
          page.$refs.aliForm.submit();
          break;
      }
    },

    checkWXPay() {
      const page = this;

      if (page.checkPayTimer) return;
      page.checkPayTimer = true;

      api
        .post(
          apis.pay.weixinCheck,
          {
            orderNo: page.order.orderNo,
          },
          { error: false }
        )
        .then((res) => {
          console.log(res);
          page.checkPayTimer = false;
          const failStatus = ["REFUND", "CLOSED", "REVOKED", "PAYERROR"];
          if (res.trade_state === "SUCCESS") {
            page.paytime = 0;
            page.pay = "";
            page.payResult = "success";
          } else if (-1 !== failStatus.indexOf(res.trade_state)) {
            page.paytime = 0;
            page.pay = "";
            page.payResult = "fail";
          } else {
            if (page.pay == "weixin") setTimeout(page.checkWXPay, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
          page.checkPayTimer = false;
          if (page.pay == "weixin") setTimeout(page.checkWXPay, 3000);
        });
    },

    checkALiPay() {
      const page = this;
      return api
        .post(apis.order.detail, {
          orderId: this.orderId,
        })
        .then((res) => {
          if (res.orderStatus == "1") {
            page.paytime = 0;
            page.pay = "";
            page.payResult = "success";
          } else if (res.orderStatus != "0") {
            page.paytime = 0;
            page.pay = "";
            page.payResult = "fail";
          }
        });
    },

    closePay(type, checkPay) {
      const page = this;
      console.log(type, checkPay);
      page.pay = "";
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("../../components/modal/modal.less");
@import url("./pay.less");
</style>
