<template>
  <div class="cart_steps">
    <ul>
      <li :class="{ done: props.step > 1, do: props.step === 1 }">
        我的购物车
      </li>
      <li :class="{ done: props.step > 2, do: props.step === 2 }">
        填写订单信息
      </li>
      <li :class="{ do: props.step === 3 }">提交订单</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["step"],
  setup(props) {
    return { props };
  },
};
</script>

<style lang="less" scoped>
.cart_steps {
  height: 110px;
  width: 100%;
  position: absolute;
  top: 35px;
  z-index: 0;

  ul {
    width: 1200px;
    margin: 0 auto;
    font-size: 12px;
    text-align: right;
    padding-top: 40px;

    li {
      position: relative;
      padding-top: 28px;
      display: inline-block;
      vertical-align: top;
      width: 170px;
      text-align: center;
    }

    li::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -10px;
      display: block;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      line-height: 20px;
      font-size: 12px;
      color: #999999;
      text-align: center;
      background: #e2e2e2;
      z-index: 2;
    }

    li::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      top: 9px;
      right: 50%;
      background: #e2e2e2;
      z-index: 1;
    }

    li:nth-child(1)::before {
      content: "1";
    }

    li:nth-child(2)::before {
      content: "2";
    }

    li:nth-child(3)::before {
      content: "3";
    }

    li:first-child::after {
      display: none;
    }

    li:last-child {
      width: 100px;
      text-align: right;

      &::before {
        left: 100%;
        margin-left: -20px;
      }

      &::after {
        right: 0%;
        width: 180%;
      }
    }

    li.done,
    li.do {
      &::before {
        color: #fff;
        background: #0273ff;
      }

      &::after {
        background: #0273ff;
      }
    }

    li.done {
      color: #999999;

      &::before {
        font-size: 0;
        background: #0273ff
          url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSIjRkZGIiBkPSJNMTUgNy42TDguODQ3IDE0bC0uMDAxLS4wMDFWMTRMNSAxMGwxLjUzOC0xLjYgMi4zMDggMi40TDEzLjQ2MiA2eiIvPjwvc3ZnPg==")
          no-repeat center;
        background-size: 16px;
      }
    }
  }
}
</style>
